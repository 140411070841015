import React from "react"
import { graphql } from "gatsby"
import PageTypes from "../../components/page-types/_PageTypes"
import moment from "moment"

const StoryblokStartPageTemplate = ({ data, venue, location }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const navigationNode = data.navigation
  const navigation = navigationNode ? JSON.parse(navigationNode.content) : {}

  const capitalize = (string) =>
    string?.charAt(0)?.toUpperCase() + string?.slice(1)

  //   console.log("Storyblok Content", content)

  const highlights = data.highlights.nodes.map((el) => {
    const content = JSON.parse(el.content)
    // console.log("content", content)
    return {
      headline: content.title ?? el.name,
      url: el.full_slug,
      kicker: content.kicker,
      type: content.category
        ? content.category.map((e) => capitalize(e)).join(", ")
        : "Info",
      category: content.venue
        ? capitalize(content.venue)
        : capitalize(content.theme),
      image: content.image ? content.image : content.cover ? content.cover : "",
      date: content.dates && content.dates[0],
      moreDates: content.dates && content.dates.length > 1,
    }
  })

  const events = data.events.nodes
    .map((el) => {
      const content = JSON.parse(el.content)

      const dates = content.dates
        ?.map((node) => {
          const formattedDatetime = moment(node.datetime).format(
            "MM/DD/YYYY HH:MM:SS"
          )
          const currentDate = new Date()
          const eventDate = new Date(formattedDatetime)

          if (eventDate >= currentDate) {
            //console.log(node)
            return node
          } else {
            return
          }
        })
        .sort((a, b) => (a.datetime > b.datetime ? 1 : -1))
      // console.log("content", content)
      return {
        headline: content.title ?? el.name,
        url: el.full_slug,
        kicker: content.kicker,
        type: content.category
          ? content.category.map((e) => capitalize(e)).join(", ")
          : "Info",
        category: content.venue
          ? capitalize(content.venue)
          : capitalize(content.theme),
        image: content.image,
        date: dates && dates[0],
        moreDates: content.dates && content.dates.length > 1,
      }
    })
    .filter((event) => {
      // format date for Safari
      const formattedDate = moment(event?.date?.datetime).format(
        "MM/DD/YYYY HH:MM:SS"
      )
      const currentDate = new Date()
      const eventDate = new Date(formattedDate)
      if (event.date !== undefined && eventDate >= currentDate) {
        return event
      }
      return null
    })
    .sort((a, b) => (a.date.datetime > b.date.datetime ? 1 : -1))

  // console.log(events)

  return React.createElement(PageTypes(content.component), {
    key: node.uid,
    content: content,
    name: node.name,
    type: content.component,
    navigation: navigation,
    venue: venue,
    location: location,
    highlights: highlights,
    events: events,
  })
}

export const query = graphql`
  query StoryblokStartPageTemplateQuery($id: String!) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
    }
    highlights: allStoryblokEntry(
      filter: { field_homepage_boolean: { eq: true } }
    ) {
      nodes {
        name
        full_slug
        content
      }
    }
    events: allStoryblokEntry(
      filter: {
        field_component: { eq: "event_page" }
        full_slug: { regex: "/^((?!archiv).)*$/s" }
      }
    ) {
      nodes {
        name
        full_slug
        content
      }
    }
    navigation: storyblokEntry(full_slug: { eq: "global-navigation" }) {
      content
    }
  }
`

export default StoryblokStartPageTemplate
